import Papa from 'papaparse';
import { read, utils as xlsxUtils } from 'xlsx';
import { ContactMap, ContactMapKey } from './contactMap';

export interface ProcessedFileData {
	headers: string[];
	data: (Record<string, unknown> | unknown)[];
	mappings: Partial<Record<ContactMapKey, ColumnMapping>>;
	mapped: Record<string, string>;
}

export interface ColumnMapping {
	key: number;
	header: string;
	preview: string[];
	mappedTo?: string;
	ignore: boolean;
}

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
export class FileProcessor {
	private static readonly MAX_FILE_SIZE_MB = 10;

	private static createColumnMappings(
		headers: string[],
		data: any[],
		fileType: 'csv' | 'excel',
	): { mappings: Record<string, ColumnMapping>; mapped: Record<string, string> } {
		const mapped: Record<string, string> = {};

		const mappings = headers.reduce(
			(prev, header, key) => {
				const mappedTo = (Object.keys(ContactMap) as ContactMapKey[]).find(
					(k) => [k, ...(ContactMap?.[k]?.alias ?? [])]?.find?.((alias: string) => alias.trim().toLowerCase() === header.trim().toLowerCase()),
				);

				const preview = data.slice(0, 3).map((row) => (fileType === 'csv' ? row[header] || '--' : row[key] || '--'));

				const map: ColumnMapping = {
					key,
					header,
					preview,
					mappedTo,
					ignore: false,
				};

				if (mappedTo) mapped[mappedTo] = header;
				prev[header] = map;
				return prev;
			},
			{} as Record<string, ColumnMapping>,
		);

		return { mappings, mapped };
	}

	static async processCSV(file: File, onProgress: (progress: number, pending: ProcessedFileData) => void): Promise<ProcessedFileData> {
		return new Promise((resolve, reject) => {
			const result: ProcessedFileData = {
				headers: [],
				data: [],
				mappings: {},
				mapped: {},
			};

			Papa.parse(file, {
				header: true,
				skipEmptyLines: true,
				worker: true,
				chunk: async (results) => {
					if (!result.headers.length) {
						result.headers = results.meta.fields || [];
					}

					results.data.forEach((row) => result.data.push(row));

					const percent = Math.min(100, Math.floor((results.meta.cursor / file.size) * 100));
					onProgress(percent, result);
				},
				complete: () => {
					const { mappings, mapped } = FileProcessor.createColumnMappings(result.headers, result.data, 'csv');
					result.mappings = mappings;
					result.mapped = mapped;
					resolve(result);
				},
				error: reject,
			});
		});
	}

	static async processExcel(file: File, onProgress: (progress: number) => void): Promise<ProcessedFileData> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = (event) => {
				try {
					const bstr = event.target?.result as string;
					const workBook = read(bstr, { type: 'binary', raw: true, cellDates: true });
					const workSheet = workBook.Sheets[workBook.SheetNames[0]];
					const fileData = xlsxUtils.sheet_to_json(workSheet, { header: 1 }) as any[];
					const headers = fileData.shift() as string[];
					const data = fileData;

					const { mappings, mapped } = FileProcessor.createColumnMappings(headers, data, 'excel');

					onProgress(100);

					resolve({
						headers,
						data,
						mappings,
						mapped,
					});
				} catch (error) {
					reject(error);
				}
			};

			reader.onprogress = (event) => {
				if (event.lengthComputable) {
					const percent = Math.round((event.loaded / event.total) * 100);
					onProgress(percent);
				}
			};

			reader.readAsBinaryString(file);
		});
	}

	static async processFile(file: File, onProgress: (progress: number, pending?: ProcessedFileData) => void): Promise<ProcessedFileData> {
		if (!file) {
			throw new Error('No file provided');
		}

		if (file.size / 1024 / 1024 >= this.MAX_FILE_SIZE_MB) {
			throw new Error(`File must be smaller than ${this.MAX_FILE_SIZE_MB}MB`);
		}

		return file.name.toLowerCase().endsWith('.csv') ? await this.processCSV(file, onProgress) : await this.processExcel(file, onProgress);
	}
}
