import { PaginatedContacts } from '@/types';

interface SearchHistoryData {
	keys: string[];
	entries: { [key: string]: PaginatedContacts & { timestamp: number } };
}

export class SearchHistory {
	private readonly storageKey: string;
	private readonly maxEntries: number;
	private readonly maxAgeMs: number;
	private cache: SearchHistoryData | null = null;

	constructor(uid: string, options = { maxEntries: 10, maxAgeHours: 24 }) {
		this.storageKey = `staffapp-searchHistory-${uid}`;
		this.maxEntries = options.maxEntries;
		this.maxAgeMs = options.maxAgeHours * 60 * 60 * 1000;
		this.initializeStorage();
	}

	private initializeStorage(): void {
		if (!localStorage.getItem(this.storageKey)) {
			this.saveData({
				keys: [],
				entries: {},
			});
		}
	}

	private fetchData(): SearchHistoryData {
		if (this.cache) return this.cache;
		try {
			const data = JSON.parse(localStorage.getItem(this.storageKey) || '{}');
			this.cache = data;
			return data;
		} catch {
			const defaultData = { keys: [], entries: {} };
			this.saveData(defaultData);
			return defaultData;
		}
	}

	private saveData(data: SearchHistoryData): void {
		try {
			localStorage.setItem(this.storageKey, JSON.stringify(data));
			this.cache = data;
		} catch (error) {
			// If localStorage is full, clear old entries and try again
			this.clearOldEntries(data);
			localStorage.setItem(this.storageKey, JSON.stringify(data));
			this.cache = data;
		}
	}

	private clearOldEntries(data: SearchHistoryData): void {
		const now = Date.now();
		// Remove entries older than maxAgeMs
		data.keys = data.keys.filter((key) => {
			const entry = data.entries[key];
			const isRecent = now - entry.timestamp < this.maxAgeMs;
			if (!isRecent) {
				delete data.entries[key];
			}
			return isRecent;
		});

		// If still too many entries, remove oldest ones
		while (data.keys.length > this.maxEntries) {
			let oldestKey = data.keys[0];
			let oldestTime = data.entries[oldestKey].timestamp;
			// Find the oldest entry
			data.keys.forEach((key) => {
				if (data.entries[key].timestamp < oldestTime) {
					oldestTime = data.entries[key].timestamp;
					oldestKey = key;
				}
			});
			// Remove oldest entry
			const index = data.keys.indexOf(oldestKey);
			data.keys.splice(index, 1);
			delete data.entries[oldestKey];
		}
	}

	list(): string[] {
		const data = this.fetchData();
		return [...data.keys]; // Return a copy to prevent direct modification
	}

	map(): { [key: string]: PaginatedContacts } {
		const data = this.fetchData();
		return { ...data.entries }; // Return a copy to prevent direct modification
	}

	get(searchTerm: string): PaginatedContacts | null {
		const data = this.fetchData();
		const entry = data.entries[searchTerm];
		if (!entry) return null;
		// Check if entry is expired
		if (Date.now() - entry.timestamp > this.maxAgeMs) {
			this.delete(searchTerm);
			return null;
		}
		return entry;
	}

	getLast(): PaginatedContacts | null {
		const data = this.fetchData();
		if (data.keys.length === 0) return null;
		const lastKey = data.keys[data.keys.length - 1];
		return this.get(lastKey);
	}

	add(searchTerm: string, results: PaginatedContacts): void {
		const data = this.fetchData();
		// Remove existing entry if present
		const existingIndex = data.keys.indexOf(searchTerm);
		if (existingIndex !== -1) {
			data.keys.splice(existingIndex, 1);
		}
		// Add new entry
		data.keys.push(searchTerm);
		data.entries[searchTerm] = {
			...results,
			timestamp: Date.now(),
		};
		this.clearOldEntries(data);
		this.saveData(data);
		console.log('SearchHistory: Added', searchTerm, results);
	}

	delete(searchTerm: string): void {
		const data = this.fetchData();
		const index = data.keys.indexOf(searchTerm);
		if (index !== -1) {
			data.keys.splice(index, 1);
			delete data.entries[searchTerm];
			this.saveData(data);
		}
	}

	clear(): void {
		this.cache = null;
		localStorage.removeItem(this.storageKey);
		this.initializeStorage();
	}
}
