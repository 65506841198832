import React from 'react';

const input = { bool: ['True', 'False', 'Yes', 'No', '0', '1'], date: ['mm/dd/yyyy'] };

export type ContactMapValue = {
	alias: string[];
	desc: React.ReactNode;
	example: string[];
	valuenote?: string;
	main?: boolean;
	color?: string;
	usecase: string;
	extra?: React.ReactNode;
};

const ContactMapKeys = [
	'Opt-in SMS',
	'Opt-in Email',
	'Opted Out Email',
	'Explicit SMS Opt-out',
	'Medical ID',
	'Medical ID Expiration',
	'State ID',
	'Points',
	'Points Date',
	'Custom Attribute',
	'Full Name',
	'Email Address',
	'Cell Phone',
	'Loyalty Status',
	'Fav. Store',
	'First Name',
	'Last Name',
	'Gender',
	'Birthday',
	'Signup Override Date',
	'Date Joined',
	'Street',
	'City',
	'State / Region',
	'Country',
	'Zipcode',
	'Source Id',
	'Secondary Email',
	'Home Phone',
	'Timezone',
	'Avatar (Profile Pic)',
	'IP',
	'Twitter',
	'Website',
	'Age Gate',
	'Customer Type',
] as const;

export type ContactMapKey = (typeof ContactMapKeys)[number];

export const ContactMap: Record<ContactMapKey, ContactMapValue> = {
	'Custom Attribute': {
		alias: ['cattr', 'custom attribute'],
		desc: 'Custom attributes can be used create groups and further segment your personas.',
		example: ['Employee', 'Military', 'Police'],
		main: true,
		extra: (
			<small>
				Read more on our <a href="https://support.alpineiq.com/personas#k-add-custom-attributes">Support Page</a>
			</small>
		),
		color: 'blue',
		usecase: 'Segment customers for targeted campaigns, enable special pricing rules, and customize the shopping experience.',
	},
	'Opt-in SMS': {
		alias: ['allowed_sms', 'is_opted_in', 'opted sms', 'text opt-in', 'opted into sms'],
		desc: 'Has this persona opted-in, to SMS messages?',
		example: input.bool,
		valuenote: 'If this value is left blank, "False" will be assumed.',
		main: true,
		color: 'orange',
		usecase: 'Send flash sale alerts, delivery updates, and appointment reminders. Used for retention campaigns and time-sensitive offers.',
	},
	'Opt-in Email': {
		alias: ['allowed_email', 'email opt in', 'opted email', 'email opt-in'],
		desc: 'Has this persona opted-in, to email messages?',
		example: input.bool,
		valuenote: 'If this value is left blank, "False" will be assumed.',
		main: true,
		color: 'orange',
		usecase: 'Power email marketing campaigns, send purchase receipts, and deliver monthly newsletters with personalized product recommendations.',
	},
	'Opted Out Email': {
		alias: ['opted_out_email'],
		desc: 'Has this persona opted-out of email messages?',
		example: input.bool,
		valuenote: 'If this value is left blank, "False" will be assumed.',
		main: true,
		color: 'orange',
		usecase: 'Maintain compliance with anti-spam laws and manage suppression lists for email campaigns.',
	},
	'Explicit SMS Opt-out': {
		alias: ['SMS Optout'],
		desc: 'If true, a customer will be opted out of SMS. Beware: this will override current opt-in statuses!',
		example: ['TRUE', 'FALSE'],
		main: true,
		color: 'orange',
		usecase: 'Ensure TCPA compliance and maintain a master SMS suppression list to prevent any automated messages.',
	},
	'Medical ID': {
		alias: ['medid', 'medical id', 'medicalid', 'patient_id', 'mmjidnumber', 'medical_state_id'],
		desc: "ID number of the persona's medical ID.",
		example: ['xxx'],
		main: true,
		color: 'pink',
		usecase: 'Verify patient status, enable medical discounts, and ensure compliance with state regulations for medical sales.',
	},
	'Medical ID Expiration': {
		alias: ['medidexp', 'medicalidexpiration', 'medicalidexpirationdate', 'medical_card_expiration', 'mmjidexpirationdate'],
		desc: "Expiration date for this persona's medical ID.",
		example: ['07/25/2000'],
		main: true,
		color: 'pink',
		usecase: 'Trigger renewal reminders, manage medical discounts, and maintain compliance with state verification requirements.',
	},
	'State ID': {
		alias: ['state_id', 'driverslicense', 'drivers license', 'dl', 'drivers_license', 'driver_license', 'driverlicense'],
		desc: "ID number of the persona's state ID.",
		example: ['xxx'],
		main: true,
		color: 'pink',
		usecase: 'Age verification for restricted products, fraud prevention, and maintaining regulatory compliance records.',
	},
	'Points': {
		alias: ['points', 'balance'],
		desc: (
			<>
				<p>
					Points that are uploaded will be shown as <b>Legacy Points</b>. These are added onto (or subtracted) from the current balance.
				</p>
				<p>
					<b>Legacy Points</b> never expire.
				</p>
			</>
		),
		example: ['1000'],
		main: true,
		color: 'blue',
		usecase: 'Power loyalty reward calculations, drive retention through point-based promotions, and enable point redemption features.',
	},
	'Points Date': {
		alias: ['points', 'balance'],
		desc: 'Specifies the date (in UTC timezone) which the Points are applied. This can be left blank, but should be set to the current date for most cases.',
		example: ['00/00/0000', '00/00/0000 00:00:00'],
		main: true,
		color: 'blue',
		usecase: 'Track point history, generate rewards statements, and analyze customer engagement patterns over time.',
	},
	'Full Name': {
		alias: ['fname', 'full name', 'name'],
		desc: "What is this persona's full name?",
		example: ['John Doe', 'Jane Doe'],
		main: true,
		color: 'blue',
		usecase: 'Personalize communications, verify identity for pickups, and maintain accurate customer records.',
	},
	'Email Address': {
		alias: ['email', 'email address', 'email addr', 'contact email'],
		desc: "What is this persona's email address?",
		example: ['email@email.com'],
		main: true,
		color: 'blue',
		usecase: 'Primary identifier for account access, digital receipt delivery, and marketing communications.',
	},
	'Cell Phone': {
		alias: [
			'phone',
			'mobilephone',
			'mobile_phone',
			'mobile phone',
			'mobile',
			'subscriber_number',
			'subscriber_phone_number',
			'phone_number',
			'primary phone',
			'phone number',
			'phone - mobile',
			'phone #',
			'number',
		],
		desc: "What is this persona's cell phone number?",
		example: ['000-000-0000'],
		main: true,
		color: 'blue',
		usecase: 'Enable SMS notifications, verify identity for security purposes, and send delivery alerts.',
	},
	'Loyalty Status': {
		alias: ['loyalty', 'loyalty status', 'ismember'],
		desc: 'Is this persona opted-in/registered as a loyalty member?',
		example: input.bool,
		valuenote: 'If this value is left blank, "False" will be assumed.',
		main: true,
		color: 'orange',
		usecase: 'Control access to member benefits, enable tier-based pricing, and segment for loyalty-specific campaigns.',
	},
	'Fav. Store': {
		alias: ['location name', 'fav store', 'favorite store'],
		desc: "The name or ID of this persona's favorite store.",
		example: ['My Cool Store', 'North South East'],
		usecase: 'Personalize inventory alerts, target local promotions, and analyze store performance metrics.',
	},
	'First Name': {
		alias: ['firstname', 'first_name', 'first name', 'first'],
		desc: "What is this persona's first name?",
		example: ['John', 'Jane'],
		usecase: 'Personalize customer interactions, create friendly communication tone, and segment demographics.',
	},
	'Last Name': {
		alias: ['lastname', 'last_name', 'last name', 'lname', 'last'],
		desc: "What is this persona's last name?",
		example: ['Doe'],
		usecase: 'Complete identification verification, organize customer records, and maintain formal communications.',
	},
	'Gender': {
		alias: ['gender'],
		desc: "What is this persona's gender?",
		example: ['male', 'female'],
		usecase: 'Personalize product recommendations, analyze demographic trends, and customize marketing content.',
	},
	'Birthday': {
		alias: ['birthdate', 'birth date', 'date of birth', 'birthday'],
		desc: "When is this persona's birthday?",
		example: ['00/00/0000', '00/00/0000 00:00:00'],
		usecase: 'Send birthday rewards, verify age requirements, and analyze customer demographics.',
	},
	'Signup Override Date': {
		alias: ['overrideSignupDate'],
		desc: "Forcibly set the contact's loyalty start date",
		example: ['07/25/2000'],
		valuenote: 'If this value is before the account loyalty cutoff, points will still not accrue till then.',
		usecase: 'Manage special enrollment situations, honor legacy program dates, and adjust point calculation periods.',
	},
	'Date Joined': {
		alias: ['signed up', 'signedup', 'signed_up', 'date added', 'sign up date', 'date joined'],
		desc: 'What date did this persona join loyalty?',
		example: ['00/00/0000', '00/00/0000 00:00:00'],
		usecase: 'Calculate loyalty tenure, trigger milestone rewards, and analyze customer lifecycle stages.',
	},
	'Street': {
		alias: ['addr_street', 'street', 'address1'],
		desc: 'What is this personas street address?',
		example: ['321 abc street'],
		color: 'purple',
		usecase: 'Enable delivery services, verify billing information, and analyze customer geography for expansion planning.',
	},
	'City': {
		alias: ['addr_city', 'city'],
		desc: "What is this persona's city?",
		example: ['Houston'],
		color: 'purple',
		usecase: 'Route orders to correct stores, analyze market penetration, and customize regional promotions.',
	},
	'State / Region': {
		alias: ['addr_state', 'state', 'state / region'],
		desc: "What is this persona's state/province/region?",
		example: ['Texas'],
		color: 'purple',
		usecase: 'Ensure regulatory compliance, manage tax calculations, and segment for regional marketing campaigns.',
	},
	'Country': {
		alias: ['addr_country', 'country'],
		desc: "What is this persona's country?",
		example: ['USA'],
		color: 'purple',
		usecase: 'Manage international shipping rules, ensure compliance with local laws, and segment for country-specific promotions.',
	},
	'Zipcode': {
		alias: ['addr_zipcode', 'zipcode', 'zip', 'zip code', 'postal code', 'po'],
		desc: "What is this persona's zip code?",
		example: ['00000'],
		color: 'purple',
		usecase: 'Calculate shipping rates, assign delivery zones, and analyze market coverage.',
	},
	'Source Id': {
		alias: ['srcid', 'src id', 'id', 'sourceid', 'source id'],
		desc: "An ID unique to this persona. Should not match another persona's ID.",
		example: ['4321', 'ABC10085000'],
		usecase: 'Track customer origin, link cross-platform activities, and maintain unique customer identification.',
	},
	'Secondary Email': {
		alias: ['secondaryemail'],
		desc: "What is this persona's secondary email?",
		example: ['email@email.com'],
		usecase: 'Provide account recovery options, maintain alternate contact methods, and verify high-value transactions.',
	},
	'Home Phone': {
		alias: ['homephone', 'home_phone', 'home phone', 'phone - work'],
		desc: "What is this persona's home phone?",
		example: ['000-000-0000'],
		usecase: 'Provide backup contact method, verify customer identity, and support landline-based verification.',
	},
	'Timezone': {
		alias: ['timezone', 'time zone'],
		desc: "What is this persona's timezone?",
		example: ['UTC'],
		usecase: 'Schedule communications appropriately, display correct store hours, and manage delivery time windows.',
	},
	'Avatar (Profile Pic)': {
		alias: ['avatar'],
		desc: "What is this persona's avatar (profile picture) URL?",
		example: ['mycoolurl.com'],
		usecase: 'Personalize user interface, enhance customer service interactions, and build brand community.',
	},
	'IP': {
		alias: ['ip'],
		desc: "What is this persona's IP Address?",
		example: ['127.0.0.1'],
		usecase: 'Detect suspicious activities, prevent fraud, and analyze geographic access patterns.',
	},
	'Twitter': {
		alias: ['twitter'],
		desc: "What is this persona's Twitter URL?",
		example: ['twitter.com'],
		usecase: 'Enable social media marketing integration, track social engagement, and provide social proof.',
	},
	'Website': {
		alias: ['website'],
		desc: "What is this persona's website URL?",
		example: ['website.com'],
		usecase: 'Verify business accounts, enable partnerships, and gather additional customer insights.',
	},
	'Age Gate': {
		alias: ['ageGate', 'age_gate', 'has_passed_age_gate'],
		desc: 'Has this persona passed the age gate (for your region)?',
		example: input.bool,
		usecase: 'Control access to age-restricted content, maintain compliance, and customize product visibility.',
	},
	'Customer Type': {
		alias: ['customer type'],
		desc: 'Is this customer a med or rec?',
		example: ['1 = Med', '2 = Rec', 'Med', 'Rec'],
		usecase: 'Control product access, enable appropriate pricing, and maintain separate medical and recreational inventories.',
	},
} as const;
