import { utils } from '@/helpers';
import { SignupForm } from '@/types';

export const replaceMacros = (str: string | undefined, macros?: Record<string, string | undefined>): string | undefined => {
	if (!str) return str;

	// Get current url query params from location.search
	const query = new URLSearchParams(location.search);
	const brandFromQuery = query.get('brand') || utils.user.nickname;
	if (str.includes('{{uid}}')) str = str.replaceAll('{{uid}}', utils.uid);
	if (str.includes('{{brand}}')) str = str.replaceAll('{{brand}}', brandFromQuery || '');
	if (str.includes('{{brandName}}')) str = str.replaceAll('{{brandName}}', brandFromQuery || '');
	if (str.includes('{{storeName}}')) str = str.replaceAll('{{storeName}}', macros?.storeName || '');
	return str;
};

export const getFavoriteStoreField = (form: SignupForm) => {
	return form.signupFields?.find((field) => field.property === 'favoriteStore');
};

export const cleanForm = (form: SignupForm): [SignupForm, boolean] => {
	let changed = false;
	const signupFields = form.signupFields?.map((field) => {
		if ((field.type as string) === 'select-dropdown') {
			field.type = 'single-select-dropdown';
			changed = true;
		}
		return field;
	});

	return [
		{
			...form,
			signupFields,
		},
		changed,
	];
};
